import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview`}</h1>
    <p>{`CoreMedia Adaptive Personalization enables enterprises to deliver the most appropriate content to users depending on the
‘context’ – the interaction between the user, the device, the environment and the content itself.  Through a series of steps
it can identify relevant content for individuals. It can draw on a user’s profile, commerce segment, preferences and even social network behavior. Use CoreMedia Adaptive
Personalization to deliver highly relevant and personalized content to users, at any given moment in time.`}</p>
    <p><img parentName="p" {...{
        "src": "/d378d13043c9241f5978a02076c6f9b8/Personalization_Architecture_Overview_Fine_Dia.svg",
        "alt": null
      }}></img></p>
    <p>{`The GUI is integrated into CoreMedia Studio for easy creation and testing of customer segments and selection rules (see the document model above).`}</p>
    <p>{`The Headless Server returns the rules defined in personalized content. The rules have to be evaluated on client side.`}</p>
    <h2>{`Use Cases`}</h2>
    <p>{`You can use the API to develop your own:`}</p>
    <ul>
      <li parentName="ul">{`Context sources in order to include information from other sources to personalize the content`}</li>
      <li parentName="ul">{`Search functions to access other sources`}</li>
    </ul>
    <h2>{`Prerequisites`}</h2>
    <p>{`In order to use Server-side Personalization you need a special licence. Contact your key-account manager or the `}<a parentName="p" {...{
        "href": "https://support.coremedia.com"
      }}>{`CoreMedia
Support`}</a>{`.`}</p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to develop with `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/personalization-en/content/Preface.html"
        }}>{`Adaptive Personalization`}</a>{`.`}</li>
      <li parentName="ul">{`Learn how to use personalized content in Studio with the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/artifacts/2204/webhelp/studio-user-en/content/WorkingWithPersonalizedContent.html"
        }}>{`Studio User Manual`}</a>{`
for personalization, segmentation and optimization of content for specific user groups.`}</li>
      <li parentName="ul">{`Learn how to use personalization with the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/headlessserver-en/content/PersoIntegration.html"
        }}>{`Headless Server`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      